:root {
	--yellow: linear-gradient(180deg, #f8d49a -146.42%, #fad79d -46.42%);
	--orange: #fca61f;
	--black: #242d49;
	--gray: #788097;
	--purple: linear-gradient(180deg, #bb67ff 0%, #c484f3 100%);
	--pink: #ff919d;
	--glass: rgba(255, 255, 255, 0.54);
	--boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
	--smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
	--activeItem: #cdcdcd;
}

.App {
	margin: 0 auto;
	max-width: 1480px;
	min-height: 100vh;
	background: whitesmoke;
	overflow-y: scroll;
}

/*  */
