/* .navbar {
	height: 100%;
} */

.logo {
	display: flex;
	max-width: 130px;
	height: 100%;
}
.logo img {
	width: 60%;
	margin-left: -18px;
	padding: 1.5rem;
}
.list-wrapper {
	display: flex;
	align-items: center;
}
.list-wrapper img {
	position: absolute;
	opacity: 0;
	z-index: 4;
	width: 40px;
	right: 15px;
	top: 10px;
	padding: 8px;
	border-radius: 999px;
	background-color: #dddddd;
	transition: all 0.5s ease-in-out;
	cursor: pointer;
}
.list-wrapper ul {
	top: 0;
	left: -100vw;
	z-index: 3;
	width: 100%;
	height: 100vh;
	padding-top: 140px;
	text-align: center;
	position: absolute;
	transition: all 0.5 ease-in-out;
	background-color: rgb(0, 0, 0, 0.8);
}
.list-wrapper ul li {
	list-style: none;
	margin-bottom: 15px;
}
.list-wrapper ul li a {
	display: block;
	font-size: 24px;
	padding: 10px 0;
	color: #dddddd;
	text-decoration: none;
	transition: all 0.3s ease-in-out;
}
.list-wrapper ul li a:hover {
	color: #000000;
	background-color: #dddddd;
}

@media (min-width: 950px) {
	.list-wrapper img {
		display: none;
	}
	.logo img {
		width: 70%;
	}
	.list-wrapper ul {
		padding: 0;
		width: 100%;
		height: 100%;
		text-align: left;
		position: relative;
		background-color: transparent;
	}
	.list-wrapper ul li {
		display: inline-block;
		margin-bottom: 0;
	}
	.list-wrapper ul li:not(:last-child) {
		margin-right: 10px;
	}
	.list-wrapper ul li a {
		padding: 5px;
		color: #000000;
		font-size: 16px;
	}
	.list-wrapper ul li a:hover {
		padding: 5px;
		color: gray;
		transform: scale(0.8);
		background: transparent;
	}
}
