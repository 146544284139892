.Updates {
	width: 360px;
	height: 350px;
	background: rgba(255, 255, 255, 0.25); /* Semi-transparent white */
	border-radius: 10px; /* Rounded corners */
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.25); /* Shadow effect */
	padding: 1rem;
	gap: 1rem;
	display: flex;
	flex-direction: column;
	font-size: 13px;
	margin: 0.8rem auto;
}

.update {
	display: grid;
	grid-template-columns: 50px auto 60px;
	align-items: start;
	justify-content: space-between;
	gap: 0.5rem;
	background: white;
	border-radius: 10px;
	padding: 8px 5px;
	margin: 2px;
}

.update > img {
	width: 3.2rem;
	height: 3.2rem;
}
.noti > div > span:nth-of-type(1) {
	font-weight: bold;
}
