@import url("https://fonts.googleapis.com/css2?family=Cantarell&family=Poppins&family=Spectral&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

/*Hide scrollbar*/
body ::-webkit-scrollbar {
	display: none;
	font-family: "Poppins";
}

.container {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1280px;
}
p {
	line-height: 30px;
	font-family: "Poppins", sans-serif;
}

.marquee-container {
	overflow: hidden;
	white-space: nowrap;
}

.marquee-text {
	display: inline-block;
	animation: marquee 15s linear infinite;
	animation-timing-function: linear;
	margin-bottom: 5px;
	color: blue;
}

@keyframes marquee {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}

.enter {
	animation-name: enter;
	animation-duration: 0.7s;
	animation-timing-function: ease-in;
}

@keyframes enter {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
