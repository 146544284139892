.sidebar {
	padding-top: 2rem;
	overflow-y: scroll;
	transition: all 300ms ease;
	background-color: lavender;
}

/* menu */
.menu {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}
.menuItem {
	display: flex;
	align-items: center;
	gap: 1rem;
	height: 2.5rem;
	margin-left: 2rem;
	position: relative;
	transition: all 300ms ease;
	border-radius: 0.7rem;
	font-size: 14px;
}

.menuItem:hover {
	cursor: pointer;
}

.menu .menuItem:last-child {
	position: absolute;
	bottom: 2.3rem;
	width: 100%;
}

.active {
	background: var(--activeItem);
	margin-left: 0;
}
.active::before {
	content: "";
	width: 8px;
	height: 100%;
	background: var(--gray);
	margin-right: calc(1rem - 8px);
}
