.home {
	flex-grow: 1;
	min-width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	overflow-y: scroll;
}

.home .title {
	width: 100%;
	font-size: 18px;
	text-align: center;
}

.home .title .name {
	line-height: 50px;
	font-family: "Poppins", sans-serif;
	font-size: 30px;
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.animate-fade-in {
	animation-name: fade-in;
	animation-duration: 5s;
	animation-fill-mode: forwards;
}

.jello {
	-webkit-animation: jello-diagonal-1 1.2s both;
	animation: jello-diagonal-1 1.2s both;
}

@-webkit-keyframes jello-diagonal-1 {
	0% {
		-webkit-transform: skew(0deg 0deg);
		transform: skew(0deg 0deg);
	}

	30% {
		-webkit-transform: skew(25deg 25deg);
		transform: skew(25deg 25deg);
	}

	40% {
		-webkit-transform: skew(-15deg, -15deg);
		transform: skew(-15deg, -15deg);
	}

	50% {
		-webkit-transform: skew(15deg, 15deg);
		transform: skew(15deg, 15deg);
	}

	65% {
		-webkit-transform: skew(-5deg, -5deg);
		transform: skew(-5deg, -5deg);
	}

	75% {
		-webkit-transform: skew(5deg, 5deg);
		transform: skew(5deg, 5deg);
	}

	100% {
		-webkit-transform: skew(0deg 0deg);
		transform: skew(0deg 0deg);
	}
}

@keyframes jello-diagonal-1 {
	0% {
		-webkit-transform: skew(0deg 0deg);
		transform: skew(0deg 0deg);
	}

	30% {
		-webkit-transform: skew(25deg 25deg);
		transform: skew(25deg 25deg);
	}

	40% {
		-webkit-transform: skew(-15deg, -15deg);
		transform: skew(-15deg, -15deg);
	}

	50% {
		-webkit-transform: skew(15deg, 15deg);
		transform: skew(15deg, 15deg);
	}

	65% {
		-webkit-transform: skew(-5deg, -5deg);
		transform: skew(-5deg, -5deg);
	}

	75% {
		-webkit-transform: skew(5deg, 5deg);
		transform: skew(5deg, 5deg);
	}

	100% {
		-webkit-transform: skew(0deg 0deg);
		transform: skew(0deg 0deg);
	}
}

.home .title button {
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 20px;
	font-weight: 600;
	margin-top: 30px;
	color: #ffff;
	padding: 15px 30px;
	border-radius: 999px;
	background-color: #04009a;
	font-family: "Poppins", sans-serif;
}

.home .title button:hover {
	background-color: #77acf1;
	font-weight: 800;
}

.home .person {
	width: 100%;
	margin-top: 20px;
}

.home .person img {
	width: 50%;
	display: block;
	margin: 3rem auto 0 auto;
	border-radius: 50%;
}

@media (min-width: 800px) {
	.home {
		flex-direction: row;
		justify-content: space-between;
	}

	.home .title,
	.home .person {
		width: 50%;
		margin-top: 0;
		text-align: left;
		font-size: 20px;
	}

	.home .person img {
		width: 35%;
		margin-left: 4rem;
		border-radius: 50%;
	}
}
