.RightSide {
	width: 100%;
	display: flex;
	height: 100vh;
	flex-direction: column;
	position: relative;
	overflow-y: scroll;
	transition: all 300ms ease;
	background-color: lavender;
}

@media screen and (max-width: 1200px) {
	.RightSide {
		justify-content: center;
	}
}

@media screen and (max-width: 768px) {
	.RightSide {
		width: 100%;
	}
	.RightSide > div {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
